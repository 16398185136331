/* ==========================================================================
   THUMBNAIL AND LABEL TILE ITEM
   ========================================================================== */

//-// Thumbnail and Label Tile
//-//
//-// Tile Items with simple Thumbnail and Label
//-//
//-// **NOTE:** This tile retains a stacked layout in all breakpoints.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="tile-item tile-thumb-label" style="max-width: 208px; margin: 0 auto;">
//-//   <picture class="tile-img">
//-//     <source class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_03.jpg" media="(max-width: 768px)">
//-//     <source class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" media="(min-width: 769px)">
//-//     <img class="img-fluid" srcset="https://stg-cms.avery.com/static-ui/src/images/homepage/tiles/GI_04.jpg" alt="">
//-//   </picture>
//-//   <div class="tile-text tile-item-label">
//-//     <span>Holiday</span>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.thumbnail-and-label

// FIXME: Deprecated - Remove `.tile-thumb-label` and all its nested styles once verified not/no longer used.
.tile-thumb-label {
  @include tile-thumb-label();

  .text-truncate-multiline {
    text-align: center;

    @include media-breakpoint-down(sm) {
      max-height: 40px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 40px;
    }
  }
}

/* ==========================================================================
   CATEGORY THUMBNAIL AND LABEL TILE ITEM
   ========================================================================== */

//-// Thumbnail and Label Category Tile
//-//
//-// Category Tile Items with a simple Thumbnail and Label
//-//
//-// **NOTE:** This tile has a list style on XS and SM.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="tile-item tile-category-thumb-label" style="max-width: 208px; margin: 0 auto;">
//-//   <div class="tile-img tile-img-cursor">
//-//     <picture>
//-//       <source class="img-fluid" srcset="https://dev-cms.avery.com/assets/svg/templates/template-categories/Address_Labels.svg" media="(max-width: 768px)">
//-//       <source class="img-fluid" srcset="https://dev-cms.avery.com/assets/svg/templates/template-categories/Address_Labels.svg" media="(min-width: 769px)">
//-//       <img class="img-fluid" srcset="https://dev-cms.avery.com/assets/svg/templates/template-categories/Address_Labels.svg" alt="">
//-//     </picture>
//-//   </div>
//-//   <div class="tile-text tile-item-label text-truncate">
//-//     <span>Address &amp; Shipping Labels</span>
//-//   </div>
//-// </div>
//-//
//-// Styleguide tiles.tile-category-thumb-label

// FIXME: Deprecated - Remove `.tile-category-thumb-label` and all its nested styles once verified not/no longer used.
.tile-category-thumb-label {
  @include tile-thumb-label();
  @include tile-collapsable(116px);

  background: $main-white;

  .tile-item-label {
    margin: 0;
    padding: rem(10);

    @include media-breakpoint-down(sm) {
      height: auto;
      margin: 0;
      font-weight: bold;
      text-align: left;
    }

    @include media-breakpoint-only(md) {
      height: 80px;
    }
    @include media-breakpoint-up(lg) {
      height: 55px;
    }
  }
}
