@use "sass:math";

/* ==========================================================================
   TILE SEARCH RESULT ITEM (LIST & GRID COMMON)

   NOTE: These may also be referred to as 'Cards'.
   NOTE: All tiles in the search result page will contain these styles.
   ========================================================================== */

.tile-search-result-item .tile-container {
  @include tile-container-common();

  .tile-text {
    &.text-main-long {
      font-weight: bold;
    }
  }

  .purchase-price {
    display: block;
    font-size: rem(18);
    line-height: 1.3;
  }
}

.search-tile-type {
  font-size: rem(16);
  line-height: 1; // NOTE: The title text should not follow default line height of 1.5 because it is simply display text.
  border-radius: rem(4);
  margin: rem(5);
  background-color: $base-blue;
  color: $main-white;

  p {
    padding: rem(4);
    margin: 0;
  }
}

/* ==========================================================================
   TILE SEARCH RESULT ITEM - Blank Labels - (LIST & GRID COMMON)

   NOTE: These may also be referred to as 'Cards'.
   NOTE: All tiles in the search result page will contain these styles.
   ========================================================================== */

.tile-search-bl-item .tile-container {
  .purchase-price {
    font-size: rem(13);

    @include media-breakpoint-up(lg) {
      font-size: rem(15);
    }
  }
}

/* ==========================================================================
   TILE SEARCH RESULT ITEM (GRID) ONLY - Utilizes Bootstrap's Card Component

   NOTE: All GRID tiles in the search result page will contain these styles.
   ========================================================================== */

//-// Search Results Tile(s) - Grid
//-//
//-// A set of Search Results Tiles in 'Grid' View. 4 possible tiles would be a Product Tile, Template Tile, Help Tile, and an Ideas Tile.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div style="max-width: 1110px; margin: auto;">
//-//   <div class="row">
//-//     <div class="col-md-4 col-xl-3 d-none d-md-block">
//-//       <div class="tile-product-filter-item tile-item tile-grid-item tile-search-result-item">
//-//         <a class="router-link" href="https://www.avery.com/products/labels/5160">
//-//           <div class="card tile-container">
//-//             <div class="card-header">
//-//               <div class="search-tile-type">
//-//                 <p>Products</p>
//-//               </div>
//-//               <div class="flexible-img flexible-img-vertical">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/products/labels/72782-05160-p39t">
//-//               </div>
//-//             </div>
//-//             <div class="card-body tile-block">
//-//               <div class="card-text tile-text text-main-long">
//-//                 <p class="text-truncate-multiline">Easy Peel® Address Labels</p>
//-//               </div>
//-//               <div class="card-text tile-text text-item-number">
//-//                 Product <strong>5160</strong>
//-//               </div>
//-//               <div class="card-text tile-text text-details">
//-//                 <div class="detail-content">
//-//                   <ul>
//-//                     <li class="text-truncate">3,000 labels</li><li class="text-truncate">1" x 2-5/8" Rectangle</li><li class="text-truncate">White</li><li class="text-truncate">Laser</li>
//-//                   </ul>
//-//                 </div>
//-//               </div>
//-//               <div class="available-in">
//-//                 <div class="row">
//-//                   <div class="col-12">
//-//                     <span class="available-in-text">
//-//                       <span>Available in:</span>
//-//                       <img src="https://www.avery.com/assets/svg/weprint_logo_blue.svg" style="width: 70px; display: inline-block; margin-top: -6px;">
//-//                     </span>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//             <div class="card-footer">
//-//               <div class="purchase-action">
//-//                 <div class="row">
//-//                   <div class="col-5">
//-//                     <span class="purchase-price">$34.99</span>
//-//                   </div>
//-//                   <div class="col-7 text-right">
//-//                     <button class="btn btn-sm btn-avry btn-purchase-action">
//-//                       Add to Cart
//-//                     </button>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-md-4 col-xl-3 d-none d-md-block">
//-//       <div class="tile-template-filter-item tile-item tile-grid-item tile-search-result-item">
//-//         <a class="router-link" href="https://www.avery.com/templates/5160">
//-//           <div class="card tile-container">
//-//             <div class="card-header">
//-//               <div class="search-tile-type">
//-//                 <p>Templates</p>
//-//               </div>
//-//               <div class="flexible-img flexible-img-vertical">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_225/web/templates/line-art/5160">
//-//               </div>
//-//             </div>
//-//             <div class="card-body tile-block">
//-//               <div class="card-text tile-text text-main-long">
//-//                 <p class="text-truncate-multiline">Easy Peel Address Labels</p>
//-//               </div>
//-//               <div class="card-text tile-text text-details text-item-number">
//-//                 <p class="text-truncate">Template <span class="bold-text">5160</span></p>
//-//               </div>
//-//               <div class="card-text tile-text text-details text-template-size">
//-//                 <div class="detail-content">
//-//                   <p class="text-truncate">1" x 2-5/8"</p>
//-//                 </div>
//-//               </div>
//-//               <div class="card-text tile-text text-details text-template-details">
//-//                 <div class="detail-content">
//-//                   <p class="text-truncate">White</p>
//-//                   <p class="text-truncate">
//-//                     30 per Sheet
//-//                   </p>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//             <div class="card-footer">
//-//               <div class="available-in">
//-//                 <div class="row">
//-//                   <div class="col-12">
//-//                     <span class="available-in-text">
//-//                       <span>Available in:</span>
//-//                       <img src="https://www.avery.com/assets/svg/weprint_logo_blue.svg" style="width: 70px;">
//-//                     </span>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-md-4 col-xl-3 d-none d-md-block">
//-//       <div class="tile-search-result-item tile-search-result-item tile-item tile-grid-item">
//-//         <a href="https://www.avery.com/help/product-support">
//-//           <div class="card tile-container">
//-//             <div class="card-header">
//-//               <div class="search-tile-type">
//-//                 <p>Help</p>
//-//               </div>
//-//               <div class="flexible-img flexible-img-vertical">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/help/icon-help-product">
//-//               </div>
//-//             </div>
//-//             <div class="card-body tile-block">
//-//               <div class="card-text tile-text text-main-long">
//-//                 <p class="text-truncate-multiline">Product Support</p>
//-//               </div>
//-//               <div class="card-text tile-text text-details">
//-//                 <div class="detail-content">
//-//                   <p class="text-truncate-multiline">
//-//                     Get answers to the most popular questions regarding our products.
//-//                   </p>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-md-4 col-xl-3 d-none d-md-block">
//-//       <div class="tile-search-result-item tile-search-result-item tile-item tile-grid-item">
//-//         <a href="https://www.avery.com/articles/take-the-mystery-out-of-mail-merge">
//-//           <div class="card tile-container">
//-//             <div class="card-header">
//-//               <div class="search-tile-type">
//-//                 <p>Ideas</p>
//-//               </div>
//-//               <div class="flexible-img flexible-img-vertical">
//-//                 <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_300/web/ideas/article/take-the-mystery-out-of-mail-merge">
//-//               </div>
//-//             </div>
//-//             <div class="card-body tile-block">
//-//               <div class="card-text tile-text text-main-long">
//-//                 <p class="text-truncate-multiline">Take the Mystery Out of Mail Merge</p>
//-//               </div>
//-//               <div class="card-text tile-text text-details">
//-//                 <div class="detail-content">
//-//                   <p class="text-truncate-multiline">
//-//                     In just a few steps, you can create your own mailing list and perform a "mail merge" for fast, easy white or clear labels just the way you want them.
//-//                   </p>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.search-results-grid

// FIXME: Deprecated - Remove `.tile-search-result-item.tile-grid-item` and all its nested styles once verified not/no longer used.
.tile-search-result-item.tile-grid-item {
  height: calc(100% - #{$grid-gutter-width});

  .tile-container {
    @include tile-container-grid-common();
    height: 100%;
    padding-bottom: 0;

    .flexible-img {
      @include media-breakpoint-only(md) {
        height: 200px;
      }
      @include media-breakpoint-only(lg) {
        height: 208px;
      }
      @include media-breakpoint-only(xl) {
        height: 240px;
      }
    }

    .tile-text {
      &.text-main-long {
        height: 40px;
        margin: 0;

        p {
          margin: 0;
        }

        .text-truncate-multiline {
          max-height: 40px;
          margin-right: 0;
        }
      }

      &.text-details {
        ul {
          margin: 0;
          padding-left: rem(18);
        }

        .detail-content {
          height: auto;
        }
        .text-truncate-multiline {
          max-height: none;
          margin: 0;
        }
      }
    }

    .card-footer {
      background-color: $light-gray;
      position: relative;
    }
  }
}

// Product Search Tile
.tile-product-filter-item.tile-grid-item.tile-search-result-item {
  .tile-container {
    padding-bottom: 0;
  }
}

/* ==========================================================================
   TILE SEARCH RESULT ITEM (LIST) ONLY - Utilizes Bootstrap's Row

   NOTE: All LIST tiles in the search result page will contain these styles.
   ========================================================================== */

//-// Search Results Tile(s) - List
//-//
//-// A set of Search Results Tiles in 'List' View. 4 possible tiles would be a Product Tile, Template Tile, Help Tile, and an Ideas Tile.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// Markup:
//-// <div class="container mx-auto">
//-//   <div class="row">
//-//     <div class="col-12">
//-//       <div class="tile-product-filter-item tile-item tile-list-item tile-search-result-item">
//-//         <a class="router-link" href="https://www.avery.com/products/labels/5160">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-sm-3 col-md-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/products/labels/72782-05160-p39t">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-sm-6 col-md-7 col-lg-7 full-height-sm-up gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block">
//-//                   <div class="search-tile-type">
//-//                     <p>Products</p>
//-//                   </div>
//-//                   <div class="tile-text text-main-long">
//-//                     <p class="text-truncate-multiline">Easy Peel® Address Labels</p>
//-//                   </div>
//-//                   <div class="tile-text tile-text text-item-number">
//-//                     Product <strong>5160</strong>
//-//                   </div>
//-//                   <div class="tile-text text-details">
//-//                     <div class="detail-content">
//-//                       <ul>
//-//                         <li class="text-truncate">3,000 labels</li><li class="text-truncate">1" x 2-5/8" Rectangle</li><li class="text-truncate">White</li><li class="text-truncate">Laser</li>
//-//                       </ul>
//-//                     </div>
//-//                   </div>
//-//                   <div class="available-in">
//-//                     <div class="row">
//-//                       <div class="col-12">
//-//                         <span class="available-in-text">
//-//                           <span>Available in:</span>
//-//                           <img src="assets/svg/weprint_logo_blue.svg" style="width: 65px; display: inline-block;">
//-//                         </span>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//               <div class="col-12 col-sm-3 col-md-12 col-lg-3 col-list-item-action full-height">
//-//                 <div class="tile-list-actions tile-purchase-action bg-gray full-height">
//-//                   <span class="purchase-price">$34.99</span>
//-//                   <button class="btn btn-avry btn-purchase-action">
//-//                     Add to Cart
//-//                   </button>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-12">
//-//       <div class="tile-template-filter-item tile-item tile-list-item tile-search-result-item">
//-//         <a class="router-link" href="https://www.avery.com/templates/5160">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_225/web/templates/line-art/5160">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-lg-10 full-height gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block full-height">
//-//                   <div class="row">
//-//                     <div class="col-12">
//-//                       <div class="search-tile-type">
//-//                         <p>Templates</p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                   <div class="row row-list-details full-height align-items-lg-center">
//-//                     <div class="col-12 col-lg-5">
//-//                       <div class="tile-text text-main-long">
//-//                         <p class="text-truncate-multiline">Easy Peel Address Labels</p>
//-//                       </div>
//-//                       <div class="tile-text text-details text-item-number">
//-//                         <p class="text-truncate">Template <span class="bold-text">5160</span></p>
//-//                       </div>
//-//                       <div class="tile-text text-details text-template-size">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate">1" x 2-5/8"</p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-3">
//-//                       <div class="tile-text text-details text-template-details">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate">White</p>
//-//                           <p class="text-truncate">
//-//                             30 per Sheet
//-//                           </p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-4 mt-auto mt-lg-0">
//-//                       <div class="tile-list-actions available-in-content full-height">
//-//                         <span class="available-in-label">Available in:&nbsp;</span>
//-//                         <img class="we-print-svg-logo" src="https://www.avery.com/assets/svg/weprint_logo_blue.svg">
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-12">
//-//       <div class="tile-search-result-item tile-search-result-item tile-item tile-list-item">
//-//         <a href="https://www.avery.com/help/product-support">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/help/icon-help-product">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-lg-10 full-height gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block full-height">
//-//                   <div class="row">
//-//                     <div class="col-12">
//-//                       <div class="search-tile-type">
//-//                         <p>Help</p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                   <div class="row row-list-details align-items-lg-center">
//-//                     <div class="col-12 col-lg-5">
//-//                       <div class="tile-text text-main-long">
//-//                         <p class="text-truncate-multiline">Product Support</p>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-7">
//-//                       <div class="tile-text text-details">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate-multiline">
//-//                             Get answers to the most popular questions regarding our products.
//-//                           </p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//     <div class="col-12">
//-//       <div class="tile-search-result-item tile-search-result-item tile-item tile-list-item">
//-//         <a href="https://www.avery.com/articles/have-you-tried-these-mailing-and-shipping-hacks">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_300/web/ideas/article/have-you-tried-these-mailing-and-shipping-hacks">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-lg-10 full-height gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block full-height">
//-//                   <div class="row">
//-//                     <div class="col-12">
//-//                       <div class="search-tile-type">
//-//                         <p>Ideas</p>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                   <div class="row row-list-details align-items-lg-center">
//-//                     <div class="col-12 col-lg-5">
//-//                       <div class="tile-text text-main-long">
//-//                         <p class="text-truncate-multiline">Have You Tried These Mailing and Shipping Hacks?</p>
//-//                       </div>
//-//                     </div>
//-//                     <div class="col-12 col-lg-7">
//-//                       <div class="tile-text text-details">
//-//                         <div class="detail-content">
//-//                           <p class="text-truncate-multiline">
//-//                             &gt;When it comes to our mailing and shipping labels, we’re just crazy about them. They’re durable, customizable and guaranteed to be smudge free and jam free.
//-//                           </p>
//-//                         </div>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.search-results-list

// FIXME: Deprecated - Remove `.tile-search-result-item.tile-list-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-search-result-item.tile-list-item .tile-container {
  @include tile-container-list-common();
  @include tile-filter-container-list-common();

  height: 275px;

  .search-tile-type {
    padding: 0;
    margin: 0 0 rem(6);
  }

  .row-list-details {
    height: 185px;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-content: flex-start;

      .col-12 {
        flex: 0 0 auto;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 145px;
    }
  }

  .tile-block {
    padding-left: rem(math.div($grid-gutter-width, 2));
  }

  .tile-text {
    &.text-main-long {
      height: 40px;
      margin-bottom: rem(4);

      .text-truncate-multiline {
        max-height: 40px;

        @include media-breakpoint-up(lg) {
          max-height: 135px;
        }
      }
    }

    &.text-details {
      ul {
        margin: 0;
        padding: 0;
      }

      .detail-content {
        .search-list,
        .text-truncate-multiline {
          max-height: 135px;
        }
      }
    }
  }

  .purchase-price {
    @include media-breakpoint-up(lg) {
      font-size: rem(22);
    }
  }

  // Override - Styles Compact Container List Item (For XS and MD breakpoints ONLY)
  // NOTE: add at the bottom of filter list item tiles
  @include compact-list-product-display-override();
}

/* ==========================================================================
    TILE SEARCH RESULT ITEM - Blank Labels - (LIST) ONLY - Utilizes Bootstrap's Row

    NOTE: All LIST tiles in the search result page will contain these styles.
    ========================================================================== */

.tile-search-bl-item.tile-list-item .tile-container {
  .purchase-price {
    @include media-breakpoint-up(lg) {
      font-size: rem(16);
    }
  }

  // Override - Styles Compact Container List Item (For XS, SM, and MD breakpoints ONLY)
  // NOTE: add at the bottom of filter list item tiles
  @include compact-list-product-display-override(true);
}
