// Banner
//
// styleguide banner

/* ==========================================================================
   Common Image Banner
   ========================================================================== */

//-// Common Image Banner
//-//
//-// An Avery Common Image Banner with the text content ***baked in***. The header, for now, has 2 variations. A Short `compact` and a Default `full` banner. Simply add the `.compact-banner` or `.full-banner` classes with the `.banner-avry` class.
//-//
//-// **NOTE:** This banner is mainly seen on dotCMS pages, but can be used on any page if needed.
//-//
//-// <span class="text-bright-red">Deprecated:</span> Do not use this class, this is no longer applicable and used globally.
//-//
//-// .compact-banner - compact banner header size
//-// .full-banner - Default banner header size
//-//
//-// Markup:
//-// <div class="banner-avry {{modifier_class}}">
//-//   <!-- This is not needed if no text is desired to be on top of image. -->
//-//   <!-- <div class="banner-header-text-content"> -->
//-//     <!-- <div class="container container-fluid-sm-down full-height"> -->
//-//       <!-- <div class="header-content"> -->
//-//         <!-- <h1>Top 5 Clutter Zones in the Home (and How to Tidy Them Up)</h1> -->
//-//       <!-- </div> -->
//-//     <!-- </div> -->
//-//   <!-- </div> -->
//-//   <div class="banner-header-content">
//-//     <div class="container container-fluid-sm-down">
//-//       <picture>
//-//         <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/main_banner_mobile.jpg" media="(max-width: 768px)">
//-//         <source srcset="//stg-cms.avery.com/static-ui/src/images/homepage/main_banner_desktop.jpg" media="(min-width: 769px)">
//-//         <img class="img-fluid" srcset="//stg-cms.avery.com/static-ui/src/images/homepage/main_banner_desktop.jpg" alt="">
//-//       </picture>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide banner.banner-common

$compact-banner-height: 200px;
$full-banner-height: 340px;
$full-banner-height-md-up: 410px;

// FIXME: Deprecated - Remove `.banner-avry` and all its nested styles once verified not/no longer used.
.banner-avry {
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    margin: 0 0 rem(40);
  }

  &.compact-banner {
    max-height: $compact-banner-height;

    @include media-breakpoint-up(md) {
      // TODO: Get max height for compact banners.
    }
  }

  &.full-banner {
    max-height: 768px;

    @include media-breakpoint-up(md) {
      max-height: $full-banner-height-md-up;
    }
  }

  h1, h1 > p {
    margin: 0;
  }

  a[href^="/"]:hover,
  a[href^="http"]:hover,
  a[data-toggle^="modal"]:hover,
  a[data-target^="#"]:hover {
    cursor: pointer;
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  // Text content
  //
  // NOTE: This sits on top of the '.banner-header-content'
  .banner-header-text-content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    overflow: hidden;

    .header-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 0 rem(10);

      > * {
        width: 100%;
        color: white;
      }

      .sub-header {
        margin: rem(10) auto 0;
      }
    }
  }

  // NOTE: Banner content is aligned of the banner header height.
  .banner-header-content {
    display: flex;
    align-items: center;

    .section-header {
      > *:only-child {
        margin-bottom: 0 !important;
      }
    }

    .sub-header {
      margin-bottom: 0;
    }
  }

  .btn.btn-avry {
    min-width: 200px;

    &:last-child {
      margin: rem(20) 0 0;
    }

    &:only-child {
      margin: rem(20) 0 !important;
    }
  }
}

/* ==========================================================================
   NO Banner IMAGE

   NOTE: This class basically sets height to the banner if the banner has no
         no image but to simply be text and a color background.
   ========================================================================== */

// FIXME: Deprecated - Remove `.banner-avry.no-banner-image` and all its nested styles once verified not/no longer used.
.banner-avry.no-banner-image {
  &.compact-banner {
    height: $compact-banner-height;

    @include media-breakpoint-up(md) {
      // TODO: Get max height for compact banners.
    }
  }

  &.full-banner {
    height: $full-banner-height;

    @include media-breakpoint-up(md) {
      height: $full-banner-height-md-up;
    }
  }
}

/* ==========================================================================
   FIXED BACKGROUND BANNER

   NOTE: It is a banner that has a fixed position and size (on all break points).
         The banner also supports HTML content that currently only supports center position.
   ========================================================================== */

// Fixed Background Banner
//
// It is a banner that has a fixed position and size (on all break points). The banner also supports HTML content that currently only supports center position.
//
// Markup:
// <section class="templates-start-section banner-avry-bg-fixed" style="background-image: url(https://img.avery.com/f_auto,q_auto,c_scale,w_2000/web/templates/template-banner-body-wash-lotion); background-position: center;">
//   <div class="container container-fluid-sm-down justify-content-center">
//     <div class="section-header">
//       <h1 class="mb-1">Avery Templates</h1>
//     </div>
//     <p class="sub-header fnt-sze-18">Find the right template to print your Avery product</p>
//     <section class="d-block mt-2 mb-2 mt-md-3 mb-md-0">
//       <div class="avry-search-input">
//         <div class="avry-search-input-form" >
//           <div class="row no-gutters align-items-center p-0">
//             <div class="col col-auto">
//               <div class="magnify-glass visible"></div>
//             </div>
//           </div>
//         </div>
//         <div class="avry-input-message"><span></span></div>
//       </div>
//     </section>
//   </div>
// </section>
//
// styleguide banner.banner-fixed-bg

.banner-avry-bg-fixed {
  height: 350px;
  background-color: $main-white;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 2155px auto;
  padding: rem(20) 0;

  > .container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: rem(20);

    @include media-breakpoint-up(md) {
      padding-top: rem(0);
      justify-content: center;
    }
  }

  .sub-header {
    margin-bottom: rem(8);
  }
}


/* ==========================================================================
   HTML BANNER - Sans Background Image

   NOTE: This banner has an image coupled with text, buttons, and/or lists.
   ========================================================================== */

// HTML Banner
//
// It is a banner that has a fixed position and size (on all break points). The banner also supports HTML content that currently only supports center position.
//
// **NOTE:** To make the title and text is duplicatd above and below the image
//
// Markup:
// <header class="hero-header bg-light-blue">
//   <div class="container container-fluid-sm-down">
//     <div class="row pt-4 pb-4">
//       <div class="col-12 d-md-none">
//         <h1 class="mb-3 text-center">Avery Desktop Downloads</h1>
//         <div class="h3 unbold-text mb-4 text-center">Design &amp; Print on Windows and Mac OSX</div>
//       </div>
//       <div class="col-12 text-center text-md-right col-md-6">
//         <img class="img-fluid mb-4" src="https://placehold.it/346x292?text=Computer!">
//       </div>
//       <div class="col-12 col-md-6 my-auto">
//         <h1 class="mb-3 d-none d-md-block">Avery Desktop Downloads</h1>
//         <div class="h3 unbold-text mb-4 d-none d-md-block">Design &amp; Print on Windows and Mac OSX</div>
//         <div class="row">
//           <div class="col-sm-12 col-md-auto text-center text-md-right mb-4">
//             <a href="#" class="btn btn-avry">
//               <i aria-hidden="true" class="fa fa-windows mr-2 text-primary"></i> Download For Windows
//             </a>
//           </div>
//           <div class="col-sm-12 col-md text-center text-md-left mb-4">
//             <a href="#" class="btn btn-avry">
//               <i aria-hidden="true" class="fa fa-apple mr-2 text-muted"></i> Download For Mac OSX
//             </a>
//           </div>
//         </div>
//         <p class="text-center text-md-left">
//           <ul>
//             <li>Mac OS X 763MB available disk space
//             <li>Microsoft Windows 10, 8, 7, Vista or XP 702MB available disk space
//           </ul>
//         </p>
//       </div>
//     </div>
//   </div>
// </header>
//
// styleguide banner.html-banner


/* ==========================================================================
   INFO BANNER
   ========================================================================== */

//-// Info Banner
//-//
//-// Text on one side and then an image on the other side
//-//
//-// Markup:
//-// <!-- Right Banner -->
//-// <section class="banner-info-avry info-banner-right bg-gray">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="banner-info">
//-//       <div class="row align-items-center">
//-//         <div class="col-12 col-md-6 text-center text-md-left">
//-//           <div class="banner-info-content">
//-//             <div class="section-header text-md-left">
//-//               <h2>Lorem Ipsum</h2>
//-//               <p class="sub-header">
//-//                 Dolor sit amet, consectetur adipisicing elit.
//-//               </p>
//-//             </div>
//-//             <ul>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio quasi sint culpa laudantium facere, odio temporibus assumenda ex maiores, autem veniam totam libero architecto asperiores recusandae ratione sequi. Hic, enim!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto voluptas voluptatem repudiandae debitis quidem ipsum aperiam cum qui voluptatum in veritatis, consectetur nobis odio praesentium voluptates inventore repellendus deleniti hic!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti minima harum nesciunt nulla similique nemo, excepturi error ducimus natus itaque ad quisquam, corporis dolores! Deleniti, omnis! Nesciunt mollitia veniam reiciendis.</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa fuga commodi pariatur adipisci ipsa. Necessitatibus aliquid blanditiis quisquam distinctio recusandae perferendis, corporis fuga dolores laudantium ea sint natus impedit facilis!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos, impedit, velit. Iusto maxime dignissimos vel architecto, odit, non enim, ipsum consectetur modi deleniti fugiat soluta. Optio necessitatibus eveniet repudiandae animi.</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//         <div class="col-12 col-md-6">
//-//           <div class="banner-info-img">
//-//              <img class="img-fluid" src="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg">
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </section>
//-// <!-- Left Banner -->
//-// <section class="banner-info-avry info-banner-right bg-gray">
//-//   <div class="container container-fluid-sm-down">
//-//     <div class="banner-info">
//-//       <div class="row align-items-center">
//-//         <div class="col-12 col-md-6">
//-//           <div class="banner-info-img">
//-//              <img class="img-fluid" src="https://img.avery.com/f_auto,q_auto,c_scale,w_550/web/ideas/landing-page/personal/ideas-personal-school.jpg">
//-//           </div>
//-//         </div>
//-//         <div class="col-12 col-md-6 text-center text-md-left">
//-//           <div class="banner-info-content">
//-//             <div class="section-header text-md-left">
//-//               <h2>Lorem Ipsum</h2>
//-//               <p class="sub-header">
//-//                 Dolor sit amet, consectetur adipisicing elit.
//-//               </p>
//-//             </div>
//-//             <ul>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio quasi sint culpa laudantium facere, odio temporibus assumenda ex maiores, autem veniam totam libero architecto asperiores recusandae ratione sequi. Hic, enim!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto voluptas voluptatem repudiandae debitis quidem ipsum aperiam cum qui voluptatum in veritatis, consectetur nobis odio praesentium voluptates inventore repellendus deleniti hic!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti minima harum nesciunt nulla similique nemo, excepturi error ducimus natus itaque ad quisquam, corporis dolores! Deleniti, omnis! Nesciunt mollitia veniam reiciendis.</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa fuga commodi pariatur adipisci ipsa. Necessitatibus aliquid blanditiis quisquam distinctio recusandae perferendis, corporis fuga dolores laudantium ea sint natus impedit facilis!</li>
//-//               <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos, impedit, velit. Iusto maxime dignissimos vel architecto, odit, non enim, ipsum consectetur modi deleniti fugiat soluta. Optio necessitatibus eveniet repudiandae animi.</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </section>
//-//
//-// styleguide banner.info-banner

// FIXME: Deprecated - Remove `.banner-info-avry` and all its nested styles once verified not/no longer used.
// NOTE: Just use Bootstrap and corresponding utility classes.
.banner-info-avry {
  padding: rem($grid-gutter-width) 0;

  .banner-info {
    border-radius: $tile-border-radius; // NOTE: Will share from the border radius of the tiles.
  }

  .banner-info-content {
    padding: rem(20);

    ul {
      padding-left: rem(16);

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }

  .banner-info-img {
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding: rem(20);
  }
}

/* ==========================================================================
   INFO VIDEO BANNER
   ========================================================================== */

//-// Info Video Banner
//-//
//-// Text on one side and then an embedded video on the other side
//-//
//-// <span class="text-bright-red">Deprecated:</span> Do not use this class, this is no longer applicable and used globally.
//-//
//-// Markup:
//-// <!-- Right Banner -->
//-// <div class="banner-info-video-avry info-video-right">
//-//   <div class="banner-header-content">
//-//     <div class="container container-fluid-sm-down">
//-//       <div class="info-video-banner-content bg-green">
//-//         <div class="row align-items-center">
//-//           <div class="col-12 col-md-6 text-center text-md-left">
//-//             <div class="info-video-content">
//-//               <h2>Check out the Avery line of industrial labels</h2>
//-//               <a class="text-link">See Templates</a>
//-//             </div>
//-//           </div>
//-//           <div class="col-12 col-md-6 text-center">
//-//             <iframe class="info-video-frame" src="https://www.youtube.com/embed/KdoZfaHvyLk?rel=0" frameborder="0" allowfullscreen=""></iframe>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-// <!-- Left Banner -->
//-// <div class="banner-info-video-avry info-video-left">
//-//   <div class="banner-header-content">
//-//     <div class="container container-fluid-sm-down">
//-//       <div class="info-video-banner-content bg-green">
//-//         <div class="row align-items-center">
//-//           <div class="col-12 col-md-6 text-center">
//-//             <iframe class="info-video-frame" src="https://www.youtube.com/embed/KdoZfaHvyLk?rel=0" frameborder="0" allowfullscreen=""></iframe>
//-//           </div>
//-//           <div class="col-12 col-md-6 text-center text-md-left">
//-//             <div class="info-video-content">
//-//               <h2>Check out the Avery line of industrial labels</h2>
//-//               <a class="text-link">See Templates</a>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide banner.video-banner

// FIXME: Remove `.banner-info-video-avry` and all its nested styles once verified not/no longer used.
.banner-info-video-avry {
  margin: rem(40) 0;

  .info-video-banner-content {
    border-radius: $tile-border-radius; // NOTE: Will share from the border radius of the tiles.
  }

  .info-video-content {
    padding: rem(20);
  }

  .info-video-frame {
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding: rem(20);
  }
}

// FIXME: Deprecated - Remove `.banner-info-video-avry` and all its nested styles once verified not/no longer used.
// Must Review what banner is stil using these styles.
//
// - New Live Text Banner is in: scss/avery/cms/dotcms/_dotcms_banner-live-text.scss
//
.banner-live {
  $banner-height: 300px;

  .banner-img {
    height: $banner-height;
    line-height: $banner-height;
    overflow: hidden;

    img {
      margin: -100% 0;
    }
  }

  .banner-info {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }
  }
}
