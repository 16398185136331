/* ==========================================================================
   TILE PRODUCT FILTER ITEM (LIST & GRID COMMON)

   NOTE: These may also be referred to as 'Cards'.
   ========================================================================== */

.tile-product-filter-item .tile-container {
  @include tile-container-common();

  // Main Text Area that appears in Card Items
  .tile-text {
    &.text-top {
      font-size: rem(14);
      margin-bottom: rem(6);
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media-breakpoint-up(sm) {
        font-size: rem(20);
      }

      @include media-breakpoint-up(md) {
        font-size: rem(22);
        line-height: rem(26);
        // TODO: Use/Import actual bold font and not use 'font-weight'.
        font-weight: 600;
      }
    }

    &.text-main-short {
      max-height: 62px;

      @include media-breakpoint-up(md) {
        max-height: 62px;
      }
    }

    &.text-main-long {
      $text-main-long-height: 40px;

      height: $text-main-long-height;
      margin-bottom: rem(3);

      .text-truncate-multiline {
        max-height: $text-main-long-height;
        margin-right: 0;
      }
    }

    &.text-item-number {
      font-size: 0.95rem; // directly made it rem per designer's request.
      line-height: 1.2;
    }

    &.text-details {
      min-height: 80px;
      margin-bottom: rem(2);

      ul {
        margin: rem(5) 0 0;
        padding-left: 0;
      }

      .detail-content {
        height: 80px;
        line-height: 1.4;
      }
    }
  }

  .tile-rating {
    margin: 0 0 rem(6);
  }

  .purchase-price {
    display: block;
    font-size: rem(22);
    line-height: 1.3;
  }
}

/* ==========================================================================
   TILE PRODUCT FILTER ITEM (Grid) ONLY - Utilizes Bootstrap's Card Component
   ========================================================================== */

//-// Product Filter Tile - Grid
//-//
//-// A Grid Tile used in the Product Filter page for 'Grid' view.
//-//
//-// **NOTE:** Remove inline max-width and margin styles when applying the markup.
//-//
//-// **NOTE:** Grid items are **NOT** display for breakpoints **SM** and below.
//-//
//-// Markup:
//-// <div class="tile-product-filter-item tile-item tile-grid-item" style="max-width: 208px; margin: 0 auto;">
//-//   <a class="router-link" href="https://www.avery.com/products/labels/5160">
//-//     <div class="card tile-container">
//-//       <div class="card-header">
//-//         <div class="flexible-img flexible-img-vertical">
//-//           <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/products/labels/72782-05160-p39t">
//-//         </div>
//-//       </div>
//-//       <div class="card-body tile-block">
//-//         <div class="card-text tile-text text-main-long">
//-//           <p class="text-truncate-multiline">Easy Peel® Address Labels</p>
//-//         </div>
//-//         <div class="card-text tile-text text-item-number">
//-//           Product <strong>5160</strong>
//-//         </div>
//-//         <div class="card-text tile-text text-details">
//-//           <div class="detail-content">
//-//             <ul>
//-//               <li class="text-truncate">3,000 labels</li><li class="text-truncate">1" x 2-5/8" Rectangle</li><li class="text-truncate">White</li><li class="text-truncate">Laser</li>
//-//             </ul>
//-//           </div>
//-//         </div>
//-//         <div class="available-in">
//-//           <div class="row">
//-//             <div class="col-12">
//-//               <span class="available-in-text">
//-//                 <span>Available in:</span>
//-//                 <img src="https://www.avery.com/assets/svg/weprint_logo_blue.svg" style="width: 70px; display: inline-block; margin-top: -6px;">
//-//               </span>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//       <div class="card-footer">
//-//         <div class="purchase-action">
//-//           <div class="row">
//-//             <div class="col-5">
//-//               <span class="purchase-price">$34.99</span>
//-//             </div>
//-//             <div class="col-7 text-right">
//-//               <button class="btn btn-sm btn-avry btn-purchase-action">
//-//                 Add to Cart
//-//               </button>
//-//             </div>
//-//           </div>
//-//         </div>
//-//       </div>
//-//     </div>
//-//   </a>
//-// </div>
//-//
//-// styleguide tiles.product-filter-grid

// FIXME: Deprecated - Remove `.tile-product-filter-item.tile-grid-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-product-filter-item.tile-grid-item .tile-container {
  @include tile-container-grid-common();

  .card-body.tile-block {
    @include media-breakpoint-up(md) {
      height: 195px;
    }
  }

  .card-footer {
    background-color: $light-gray;
  }
}

/* ==========================================================================
   TILE PRODUCT FILTER ITEM (List) ONLY - Utilizes Bootstrap's Row
   ========================================================================== */

//-// Product Filter Tile - List
//-//
//-// A List Tile used in the Product Filter page for 'List' view.
//-//
//-// There are two versions of the add to card button for list view. A big or a small button depending on the breakpoint.
//-//
//-// **NOTE:** Small Button (for XS and MD Breakpoints)
//-//
//-// **NOTE:** Regular Button (for SM, LG, and XL Breakpoints)
//-//
//-// Markup:
//-// <div class="container mx-auto">
//-//   <div class="row">
//-//     <div class="col-12">
//-//       <div class="tile-product-filter-item tile-item tile-list-item">
//-//         <a class="router-link" href="https://www.avery.com/products/labels/5160">
//-//           <div class="tile-container">
//-//             <div class="row no-gutters full-height">
//-//               <div class="col-5 col-sm-3 col-md-5 col-lg-2 full-height gutter-top gutter-bottom col-list-item-image">
//-//                 <div class="flexible-img flexible-img-horizontal">
//-//                   <img class="tile-img img-fluid img-fluid-height" src="https://img.avery.com/f_auto,q_auto,c_scale,w_200/web/products/labels/72782-05160-p39t">
//-//                 </div>
//-//               </div>
//-//               <div class="col-7 col-sm-6 col-md-7 col-lg-7 full-height-sm-up gutter-top gutter-bottom col-list-item-detail">
//-//                 <div class="tile-block">
//-//                   <div class="tile-text text-main-long">
//-//                     <p class="text-truncate-multiline">Easy Peel® Address Labels</p>
//-//                   </div>
//-//                   <div class="tile-text tile-text text-item-number">
//-//                     Product <strong>5160</strong>
//-//                   </div>
//-//                   <div class="tile-text text-details">
//-//                     <div class="detail-content">
//-//                       <ul>
//-//                         <li class="text-truncate">3,000 labels</li><li class="text-truncate">1" x 2-5/8" Rectangle</li><li class="text-truncate">White</li><li class="text-truncate">Laser</li>
//-//                       </ul>
//-//                     </div>
//-//                   </div>
//-//                   <div class="available-in">
//-//                     <div class="row">
//-//                       <div class="col-12">
//-//                         <span class="available-in-text">
//-//                           <span>Available in:</span>
//-//                           <img src="https://www.avery.com/assets/svg/weprint_logo_blue.svg" style="width: 65px; display: inline-block;">
//-//                         </span>
//-//                       </div>
//-//                     </div>
//-//                   </div>
//-//                 </div>
//-//               </div>
//-//               <div class="col-12 col-sm-3 col-md-12 col-lg-3 col-list-item-action full-height">
//-//                 <div class="tile-list-actions tile-purchase-action bg-gray full-height">
//-//                   <span class="purchase-price">$34.99</span>
//-//                   <button class="btn btn-avry btn-purchase-action">
//-//                     Add to Cart
//-//                   </button>
//-//                 </div>
//-//               </div>
//-//             </div>
//-//           </div>
//-//         </a>
//-//       </div>
//-//     </div>
//-//   </div>
//-// </div>
//-//
//-// styleguide tiles.product-filter-list

// FIXME: Deprecated - Remove `.tile-product-filter-item.tile-list-item .tile-container` and all its nested styles once verified not/no longer used.
.tile-product-filter-item.tile-list-item .tile-container {
  height: 245px;

  @include tile-container-list-common();
  @include tile-filter-container-list-common();

  // Container Heights
  @include media-breakpoint-up(sm) {
    height: 220px;
  }
  @include media-breakpoint-only(md) {
    height: 245px;
  }

  .tile-block {
    max-height: 190px;
    overflow: hidden;
  }

  .tile-rating {
    @include media-breakpoint-down(xs) {
      margin: 0 0 rem(5);
    }

    @include media-breakpoint-only(sm) {
      margin: 0 0 rem(8);
    }
  }

  .tile-text {
    &.text-main-long {
      height: 40px;

      @include media-breakpoint-only(sm) {
        margin: 0 0 rem(8);
      }
    }
  }

  .tile-purchase-action {
    // TODO: This 'Small Button' & 'Regular Button' style block(s) can be removed once these classes are no longer utilized in the Angular App.

    // Small Button
    //
    // NOTE: Bootstrap's '.btn-sm' class is used to change the button size. This could also be done in javascript.
    .btn-purchase-sm {
      @include media-breakpoint-up(sm) {
        display: none;
      }
      @include media-breakpoint-only(md) {
        display: inline-block;
      }
    }

    // Regular Button
    .btn-purchase-rg {
      @include media-breakpoint-down(xs) {
        display: none;
      }
      @include media-breakpoint-only(md) {
        display: none;
      }
    }
  }

  .purchase-price {
    margin-bottom: 40px;
  }

  // Override - Styles Compact Container List Item (For XS and MD breakpoints ONLY)
  // NOTE: add at the bottom of filter list item tiles
  @include compact-list-product-display-override();
}

/* ==========================================================================
   SEARCH RESULTS PRODUCT FILTER TILE OVERRIDES (GRID) ONLY

   NOTE: modifications to the product filter tile to meet search result
   page specific styles.
   ========================================================================== */

// .tile-search-result-item.tile-product-filter-item.tile-grid-item .tile-container {}

/* ==========================================================================
   SEARCH RESULTS PRODUCT FILTER TILE OVERRIDES (LIST) ONLY

   NOTE: modifications to the product filter tile to meet search result
   page specific styles.
   ========================================================================== */

.tile-search-result-item.tile-product-filter-item.tile-list-item .tile-container {
  .col-list-item-image,
  .col-list-item-detail {
    @include media-breakpoint-down(md) {
      height: 240px;
    }

    @include media-breakpoint-only(sm) {
      height: 235px;
    }
  }

  .tile-block {
    max-height: 225px;

    @include media-breakpoint-up(md) {
      max-height: 220px;
    }
  }

  .tile-text {
    &.text-main-long {
      $text-main-long-height: 40px;
      height: $text-main-long-height;

      .text-truncate-multiline {
        max-height: $text-main-long-height;
      }
    }

    &.text-details {
      .detail-content {
        height: 70px;
        margin-bottom: 5px;
      }
    }
  }
}
