// Responsive Classes
//
// [RESPONSIVE - BREAKPOINT UTILITIES] - Breakpoint references (for responsive design)
//
// [RESPONSIVE - UTILITIES] - Classes that applicable to specified breakpoint or breakpoint ranges.
//
// [RESPONSIVE - BOOTSTRAP UTILITIES] - Bootstrap] - There are some responsive utilities that are desired but are not ncluded in bootstrap (maybe, yet..).
// Some of these classes may no longer be needed when/if bootstrap add's these utilities.

/* ==========================================================================
   RESPONSIVE - BREAKPOINT UTILITIES

   NOTE: This will add the breakpoints to the content property of the 'body:before'. This is helpful so that javascript can access these breakpoints.
   ========================================================================== */

body:before {
  display: none;

  @each $bp in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($bp) {
      $breakpoint-value: map-get($grid-breakpoints, $bp);
      content: "#{$bp}:#{$breakpoint-value}";
    }
  }
}

/* ==========================================================================
   RESPONSIVE - UTILITIES
   ========================================================================== */

// Dividers
//
// NOTE: Adds Border Divider.
@each $bp in map-keys($sm-up-grid-breakpoints) {
  @include media-breakpoint-up($bp) {
    .top-divider-#{$bp}-up {
      border-top: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .right-divider-#{$bp}-up {
      border-right: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .bottom-divider-#{$bp}-up {
      border-bottom: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .left-divider-#{$bp}-up {
      border-left: solid $divider-weight $divider-color;
      border-radius: 0;
    }
  }

  @include media-breakpoint-down($bp) {
    .top-divider-#{$bp}-down {
      border-top: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .right-divider-#{$bp}-down {
      border-right: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .bottom-divider-#{$bp}-down {
      border-bottom: solid $divider-weight $divider-color;
      border-radius: 0;
    }
    .left-divider-#{$bp}-down {
      border-left: solid $divider-weight $divider-color;
      border-radius: 0;
    }
  }
}

// Background Color Utilities - Responsive
//
// Add these utility classes to add background colors to your element(s) in a responsive manner.
//
// Replace `*` with a specific breakpoint `"xs", "sm", "md", "lg", "xl"`.
//
// .bg-gray-*-up - Gray Background from a breakpoint upwards
// .bg-gray-*-down - Gray Background from a breakpoint downwards
// .bg-light-gray-*-up - Light Gray Background from a breakpoint upwards
// .bg-light-gray-*-down - Light Gray Background from a breakpoint downwards
// .bg-tan-*-up - Tan Background from a breakpoint upwards
// .bg-tan-*-down - Tan Background from a breakpoint downwards
// .bg-transparent-*-up - Transparent Background from a breakpoint upwards
// .bg-transparent-*-down - Transparent Background from a breakpoint downwards
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// Styleguide utilities.background-colors-responsive

// Backgrounds
@each $bp in map-keys($grid-breakpoints) {
  // Gray
  .bg-gray-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      background-color: $brownish-gray;
    }
  }
  .bg-gray-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      background-color: $brownish-gray;
    }
  }

  // Light Gray
  .bg-light-gray-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      background-color: $light-gray;
    }
  }
  .bg-light-gray-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      background-color: $light-gray;
    }
  }

  // Tan
  .bg-tan-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      background-color: $brownish-gray;
    }
  }
  .bg-tan-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      background-color: $brownish-gray;
    }
  }

  // Transparent Background
  .bg-transparent-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      background-color: transparent !important;
    }
  }
  .bg-transparent-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      background-color: transparent !important;
    }
  }
}


// Full Height
//
// NOTE: The parent element using this class will need a defined height.
@each $bp in map-keys($sm-up-grid-breakpoints) {
  .full-height-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      height: 100%;
    }
  }
}


/* ==========================================================================
   RESPONSIVE - BOOTSTRAP UTILITIES

   NOTE: Based on Bootstrap Utilities. Makes ready available to use per breakpoint.
   ========================================================================== */

// Container Fluid for SM and Below
.container.container-fluid-sm-down {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    max-width: none !important;
  }
}

// Container Fluid and no padding for SM and Below
.container-fluid-no-padding-sm-down {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
}

// Column - 5 Column Utility Class
//
// How to use: For columns, add .col-*-2 with .col-of-5-*
//
// NOTE: This is allows for a 5 column layout with bootstrap
// IMPORTANT: This is experimental and not native to Bootstrap 4, expect side affects.
//
// DEPRECATED: Use bootstrap 4 `col` classes 5 times to get a 5 column layout.
.col-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2 {
  @each $bp in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($bp) {
      &.col-of-5-#{$bp} {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

// Gutters
@each $bp in map-keys($grid-breakpoints) {
  .no-gutters-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      margin-right: 0;
      margin-left: 0;

      > [class*="col"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
@each $bp in map-keys($grid-breakpoints) {
  .no-gutters-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      margin-right: 0;
      margin-left: 0;

      > [class*="col"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

// Gutter - Individual Side Based
//
// NOTE: Adds Gutter to allocated elements. Gutter Value is based on Bootstrap
.gutter-top {
  padding-top: rem($grid-gutter-width);
}
.gutter-right {
  padding-right: rem($grid-gutter-width);
}
.gutter-bottom {
  padding-bottom: rem($grid-gutter-width);
}
.gutter-left {
  padding-left: rem($grid-gutter-width);
}

/* ==========================================================================
   SCALE UTILITIES

   TODO: Commented out Scale Utilties unless we need them more globally.
   ========================================================================== */

// Scale Down
//
// Scales an element down to a define percentage.
//
// **NOTE:** Only 65% and 75% is available, more sizes can be added though..
//
// .scle-dwn-65 - 65% scaling at all breakpoints.
// .scle-dwn-sm-65 - 65% scaling at `SM` up.
// .scle-dwn-md-65 - 65% scaling at `MD` up.
// .scle-dwn-lg-65 - 65% scaling at `LG` up.
// .scle-dwn-xl-65 - 65% scaling at `XL` up.
// .scle-dwn-75 - 75% scaling at all breakpoints.
// .scle-dwn-sm-75 - 75% scaling at `SM` up.
// .scle-dwn-md-75 - 75% scaling at `MD` up.
// .scle-dwn-lg-75 - 75% scaling at `LG` up.
// .scle-dwn-xl-75 - 75% scaling at `XL` up.
//
// markup:
// <div class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque viverra tristique massa et ultrices. Ut vitae leo ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque ut porta enim, ac suscipit arcu. Quisque ultricies at turpis eget feugiat. Curabitur vel porttitor ex. Suspendisse vel placerat erat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In vestibulum diam eu velit lacinia, a varius nulla aliquam. Mauris sit amet ex quis dui euismod rutrum eget nec nibh. Etiam suscipit metus nec eros hendrerit consequat. Curabitur risus lorem, rhoncus ut metus eget, accumsan convallis mi.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide utilities.scale-down-utilities

// NOTE: Sizes (as % percentage) can be added here...
// $scaleSizes: (
//   '65',
//   '75'
// );
// @each $size in $scaleSizes {
//   @each $bp in map-keys($grid-breakpoints) {
//     $infix: breakpoint-infix($bp, $grid-breakpoints);

//     @include media-breakpoint-up($bp) {
//       .scle-dwn#{$infix}-#{$size} {
//         transform: scale(calc(#{$size} * 0.01)) !important;
//       }
//     }
//   }
// }

// Scale Normal
//
// Normalizes size to normal/original scale.
//
// .scle-nrml - Normalizes scale at all breakpoints.
// .scle-nrml-sm - Normalize the scaling at `SM` up.
// .scle-nrml-md - Normalize the scaling at `MD` up.
// .scle-nrml-lg - Normalize the scaling at `LG` up.
// .scle-nrml-xl - Normalize the scaling at `XL` up.
//
// markup:
// <div class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque viverra tristique massa et ultrices. Ut vitae leo ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque ut porta enim, ac suscipit arcu. Quisque ultricies at turpis eget feugiat. Curabitur vel porttitor ex. Suspendisse vel placerat erat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In vestibulum diam eu velit lacinia, a varius nulla aliquam. Mauris sit amet ex quis dui euismod rutrum eget nec nibh. Etiam suscipit metus nec eros hendrerit consequat. Curabitur risus lorem, rhoncus ut metus eget, accumsan convallis mi.</div>
//
// hidedefaultstyling: true
//
// hidemarkupsnippet: true
//
// styleguide utilities.scale-normal

// @each $bp in map-keys($grid-breakpoints) {
//   $infix: breakpoint-infix($bp, $grid-breakpoints);

//   @include media-breakpoint-up($bp) {
//     .scle-nrml#{$infix} {
//       transform: scale(1) !important;
//     }
//   }
// }
